var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-login" }, [
    _c("div", { staticClass: "card" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-body tw-p-8 tw-shadow-lg" }, [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            staticClass: "logo__img",
            attrs: { src: _vm.imgLogo, alt: "NTQ Japan" },
          }),
        ]),
        _vm._v(" "),
        _vm.loginError
          ? _c(
              "ul",
              {
                staticClass: "error-message",
                attrs: { id: "errors_explanation" },
              },
              [_c("li", [_vm._v("Wrong email or password")])]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", {}, [
              _c(
                "label",
                {
                  staticClass: "form-label tw-font-bold tw-text-gray-600",
                  attrs: { for: "email" },
                },
                [_vm._v("Email")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "input-group tw-mb-2 tw-mr-sm-2" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "email",
                    type: "text",
                    placeholder: "Email",
                    autofocus: "",
                  },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tw-mt-4" }, [
              _c(
                "label",
                {
                  staticClass: "form-label tw-font-bold tw-text-gray-600",
                  attrs: { for: "password" },
                },
                [_vm._v("Password")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "input-group tw-mb-2 tw-mr-sm-2" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.password,
                      expression: "form.password",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "password",
                    type: "password",
                    placeholder: "Password",
                  },
                  domProps: { value: _vm.form.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "password", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tw-mt-4" }, [
              _c("div", { staticClass: "custom-control custom-switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.remember,
                      expression: "form.remember",
                    },
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    id: "remember_me",
                    type: "checkbox",
                    name: "remember_me",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.form.remember)
                      ? _vm._i(_vm.form.remember, null) > -1
                      : _vm.form.remember,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.form.remember,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.form, "remember", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "remember",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "remember", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label custom-control-label tw-font-bold tw-text-gray-600",
                    attrs: { for: "remember_me" },
                  },
                  [_vm._v("Remember login")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "actions tw-mt-8" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-lg btn-success",
                  attrs: { type: "submit" },
                },
                [
                  _c("i", { staticClass: "fas fa-sign-in-alt" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.loginButtonText))]),
                ]
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "hr" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-lg btn-google-login",
                  class: { disabled: _vm.loginButtonClicked },
                  attrs: { href: "/auth/google_oauth2" },
                },
                [
                  _c("i", { staticClass: "far fa-envelope" }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Login with NTQ Gmail")]),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "card-header tw-bg-primary tw-text-white tw-p-4 tw-text-center",
      },
      [
        _c("h3", { staticClass: "card-title" }, [
          _c("i", { staticClass: "fas fa-user-shield" }),
          _vm._v(" "),
          _c("span", [_vm._v("Login")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }