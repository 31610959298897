<template>
  <div class="user-login">
    <div class="card">
      <div class="card-header tw-bg-primary tw-text-white tw-p-4 tw-text-center">
        <h3 class="card-title">
          <i class="fas fa-user-shield" />
          <span>Login</span>
        </h3>
      </div>

      <div class="card-body tw-p-8 tw-shadow-lg">
        <div class="logo">
          <img
            class="logo__img"
            :src="imgLogo"
            alt="NTQ Japan"
          >
        </div>

        <ul
          v-if="loginError"
          id="errors_explanation"
          class="error-message"
        >
          <li>Wrong email or password</li>
        </ul>

        <form @submit.prevent="submitForm">
          <div class="">
            <label
              class="form-label tw-font-bold tw-text-gray-600"
              for="email"
            >Email</label>
            <div class="input-group tw-mb-2 tw-mr-sm-2">
              <input
                id="email"
                v-model="form.email"
                type="text"
                class="form-control"
                placeholder="Email"
                autofocus
              >
            </div>
          </div>

          <div class="tw-mt-4">
            <label
              class="form-label tw-font-bold tw-text-gray-600"
              for="password"
            >Password</label>
            <div class="input-group tw-mb-2 tw-mr-sm-2">
              <input
                id="password"
                v-model="form.password"
                type="password"
                class="form-control"
                placeholder="Password"
              >
            </div>
          </div>

          <div class="tw-mt-4">
            <div class="custom-control custom-switch">
              <input
                id="remember_me"
                v-model="form.remember"
                type="checkbox"
                name="remember_me"
                class="custom-control-input"
              >
              <label
                class="form-label custom-control-label tw-font-bold tw-text-gray-600"
                for="remember_me"
              >Remember login</label>
            </div>
          </div>

          <div class="actions tw-mt-8">
            <button
              class="btn btn-lg btn-success"
              type="submit"
            >
              <i class="fas fa-sign-in-alt" />
              <span>{{ loginButtonText }}</span>
            </button>
            <hr class="hr">
            <a
              href="/auth/google_oauth2"
              class="btn btn-lg btn-google-login"
              :class="{'disabled': loginButtonClicked}"
            >
              <i class="far fa-envelope" />
              <span>Login with NTQ Gmail</span>
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import imgLogo from '../../../assets/images/logo.png'

export default {
  data () {
    return {
      form: {
        email: '',
        password: '',
        remember: ''
      },
      loginError: false,
      loginButtonText: 'Login',
      loginButtonClicked: false,
      imgLogo,
    }
  },

  methods: {
    submitForm () {
      this.loginButtonText = 'Please wait...'
      this.loginButtonClicked = true

      const token = document.getElementsByName('csrf-token')[0].getAttribute('content');
      axios.defaults.headers.common['X-CSRF-Token'] = token;

      axios.post('/login', {
        email: this.form.email,
        password: this.form.password,
        remember_me: this.form.remember
      })
        .then(function(res) {
          if(res.data.message === 'NG') {
            this.loginError = true
            this.loginButtonText = 'Login again'
            this.loginButtonClicked = false
          } else {
            window.location = res.data.url
          }
        }.bind(this))
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 450px;
  max-width: 95%;
  margin: 0 auto;
  background-color: #E0F2F7;
  .btn-google-login {
    color: white;
    background-color: #b91c1c;
    &:hover {
      background-color: #991b1b;
    }
  }

  .card-body {
    .logo {
      margin-bottom: 20px;
      &__img {
        width: 150px;
        margin: 0 auto;
      }
    }
    .form-group {
      .form-control {
        font-size: 18px;
      }
    }
    .actions {
      .btn {
        width: 100%;
      }
      .hr {
        opacity: 0.7;
        margin: 30px 0;
        padding: 0;
        border: none;
        border-top: 1px solid #333;
        color: #333;
        text-align: center;
        &:after {
          content: 'or';
          display: inline-block;
          position: relative;
          top: -0.9em;
          font-size: 1.5em;
          padding: 0 0.25em;
          background-color: #E0F2F7;
        }
      }
    }
  }
  .error-message {
    width: 100% !important;
    color: red;
    font-style: italic;
    font-size: 16px;
  }
}
</style>
