import Vue from 'vue'
import UserLogin from '&/sessions/UserLogin.vue'

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('login')

  new Vue({
    el,
    render: h => h(UserLogin)
  }).$mount()
})
